// Libs
import React, { useEffect, useContext } from 'react';
import { graphql, StaticQuery } from 'gatsby';

// Components
import Layout from 'components/layout';
import SEO from 'components/seo';
import { FeaturedMarketing } from 'components/CmsComponents/FeaturedMarketing';
import Components from 'components/CmsComponents/';
import { HeaderContext } from 'context/header';


const SocialAppointment = ({ page }) => {
  const featuredMarketingContent = page[0]?.featuredMarketing;
  const components = page[0]?.components ?? [];

  const {
    setMainMarginTopLaptop,
    setMainMarginTopMobile,
    showAlertPostFeriado,
    isVisibleNovoSite,
  } = useContext(HeaderContext);

  useEffect(() => {
    if (showAlertPostFeriado || isVisibleNovoSite) {
      setMainMarginTopLaptop('9.075rem');
      setMainMarginTopMobile('7.875rem');
    } else {
      setMainMarginTopLaptop('7.063rem');
      setMainMarginTopMobile('5.875rem');
    }
  }, [showAlertPostFeriado, isVisibleNovoSite]);

  return (
    <Layout>
      <SEO
        dataSeo={page}
        image={page?.featuredMarketing?.images?.[0]?.url ?? ''}
      />
      {featuredMarketingContent && (
        <FeaturedMarketing content={featuredMarketingContent} />
      )}
      {components.length >= 1 &&
        components?.map(component => {
          return Components(component);
        })}
    </Layout>
  );
};

export default () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          gcms {
            site(where: { id: "ckgi8dg9k08b10a74cf20cxgk" }) {
              pages(where: { id: "cl0s3ocixjjtw0cmt4n7l3vf2" }) {
                featuredMarketing(locales: [pt_BR, en]) {
                  id
                  type
                  breadcrumb
                  activeBreaknews
                  breaknews {
                    id
                    name
                    type
                    singletexts
                    markdown
                  }
                  backgroundColor {
                    hex
                  }
                  images {
                    handle
                    width
                    height
                    url
                  }
                  title
                  fragments {
                    id
                    name
                    type
                    markdown
                  }
                }
                components(locales: [pt_BR, en]) {
                  __typename
                  ... on GraphCMS_SimpleContent {
                    id
                    name
                    type
                    title
                    backgroundColor {
                      hex
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                      datafragment
                      singletexts
                      assetpickerMultiple {
                        handle
                        width
                        height
                      }
                    }
                  }
                  ... on GraphCMS_DoctorAndSpecialtyContent {
                    id
                    name
                    type
                    title
                    subtitle
                    estado
                    images {
                      handle
                      width
                      height
                      url
                    }
                    specialties {
                      id
                      name
                      slug
                      customUrl
                      unity
                    }
                    fragments {
                      id
                      name
                      type
                      markdown
                    }
                  }
                }
                qa(locales: pt_BR) {
                  titulo
                  pergunta
                  autor
                  doctors {
                    active
                    assignment
                    name
                  }
                }
                seo {
                  metaTitle
                  metaDescription
                  noIndex
                  localizations {
                    metaTitle
                    metaDescription
                    noIndex
                  }
                }
              }
            }
          }
        }
      `}
      render={response => {
        return <SocialAppointment page={response.gcms.site.pages} />;
      }}
    />
  );
};
